import React, { Component } from 'react';
import './style.css';

export default class Contact extends Component {
  render() {
    return (
      <div id="contact">
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-7 col-lg-7 col-12 d-flex flex-column justify-content-center">
              <div className="studioContent text-right">
                {/* This titleArea is hidden on large, medium, and small screens, but visible on extra small screens */}
                <div className="titleArea d-block d-sm-none text-right">
                  <h1 className="commonTittle">CONTACT</h1>
                  <p className="subTitle">FILL THE FORM</p>
                </div>
                <div className="text-left">
                  <p>
                    Whether you have questions about our dance classes,
                    enrollment process, performances, or any other aspect of our
                    institution, we are here to help.
                  </p>
                  <div className="off-white">
                    <p>
                      <i className="fa-solid fa-envelope"></i>{' '}
                      bharathanjali.mng@gmail.com
                    </p>
                    <p>
                      <i className="fa-solid fa-phone"></i> +91-98444 38436
                    </p>
                    <p>
                      <i className="fa-solid fa-phone"></i> +91-98444 01834
                    </p>
                  </div>

                  <h3 className="contact-social-heading">Social Connect</h3>

                  <div className="socialIcons">
                    <i className="fa-brands fa-square-facebook fa-lg"></i>
                    <i className="fa-brands fa-square-instagram fa-lg"></i>
                  </div>

                  {/* This titleArea is visible on small, medium, and large screens, but hidden on extra small screens */}
                  <div className="titleArea d-none d-sm-block reverse text-right">
                    <h1 className="commonTittle">CONTACT</h1>
                    <p className="subTitle">FILL THE FORM</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-lg-5 col-12">
              <form>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="3"
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100 contact-button"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
