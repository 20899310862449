import React from 'react';
import './style.css';

const images = require.context('/public/images/slider', true);

class SliderSection extends React.Component {
  render() {
    return (
      <div id="sliderSection">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
          data-interval="2000"
        >
          <ol className="carousel-indicators">
            {images.keys().map((imageName, index) => (
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to={index}
                className={index === 0 ? 'active' : ''}
                key={index}
              ></li>
            ))}
          </ol>

          <div className="carousel-inner">
            {images.keys().map((imageName, index) => {
              imageName = imageName.replace('./', '');
              return (
                <div
                  className={
                    index === 0 ? 'carousel-item active' : 'carousel-item'
                  }
                  key={index}
                >
                  <img
                    className="d-block w-100"
                    src={'/images/slider/' + imageName}
                    alt={imageName}
                  />
                </div>
              );
            })}
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    );
  }
}

export default SliderSection;
