import React from 'react';
import styles from './Modal.module.css'; // Create and import your CSS module

const Modal = ({ show, onClose, photo }) => {
  if (!show) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <img
          src={photo.imageUrl}
          alt={photo.title}
          className={styles.modalImage}
        />
        <h2>{photo.title}</h2>
        <p>{photo.description}</p>
      </div>
    </div>
  );
};

export default Modal;
