import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import styles from "./Blog.module.css";

const Blog = () => {
  const [uploads, setUploads] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUploads = async () => {
      try {
        const uploadsQuery = query(
          collection(db, "uploads"),
          orderBy("createdAt", "desc"),
          limit(20) // Increase limit to display more images
        );
        const querySnapshot = await getDocs(uploadsQuery);
        const uploadsData = querySnapshot.docs.map((doc) => doc.data());
        setUploads(uploadsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching documents: ", error);
        setLoading(false);
      }
    };
    fetchUploads();
  }, []);

  const handleImageClick = () => {
    navigate("/gallery#");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className={styles.blog_section}>
      <div id="blog" className="container">
        <div className={styles.blog_content}>
          <div className={styles.columns}>
            {uploads.map((upload, index) => {
              if (index < 12) {
                return (
                  <div
                    key={index}
                    className={styles.gallery_item}
                    onClick={() => handleImageClick(index)}
                  >
                    <img
                      className={styles.gallery_image}
                      src={upload.imageUrl}
                      alt={upload.title}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
