import React, { useState, useEffect } from 'react';
import './style.css'; // Import the CSS file
// import logoWhite from './assets/images/logo-white.png'; // Adjust the path as needed
// import logoDark from './assets/images/logo-dark.png'; // Adjust the path as needed
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({page}) => {
  // State variables
  const [isMobileMenuActive, setMobileMenuActive] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);
  const [isSticky, setSticky] = useState(false);

  // Menu items
  const menuItems = [
    { href: page === "gallery" ? "/" : '', label: 'Home' },
    { href: page === "gallery" ? "/#about" : '#about', label: 'About' },
    { href: page === "gallery" ? "/#event" : '#event', label: 'Events' },
    { href: '/gallery', label: 'Gallery' },
    { href: page === "gallery" ? "/#contact" : '#contact', label: 'Contact Us' },
  ];

  // Handler functions
  const toggleMobileMenu = () => {
    setMobileMenuActive(!isMobileMenuActive);
  };

  const toggleSearch = () => {
    setSearchActive(!isSearchActive);
  };

  const handleScroll = () => {
    if (window.scrollY > 1) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    // Clean up
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`site-header header-style-one ${isSticky ? 'sticky_header' : ''}`}>
        <div className="container dark-nav radius-2">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="navigation-area">
                {/* Site Branding */}
                <div className="site-branding">
                  <a href="digital-studio.html">
                    <img src="/images/logo/1.jpg" alt={"Bharathanjali"} style={{ width: '80px', borderRadius: '8px'}} />
                  </a>
                </div>
                {/* /.site-branding */}

                {/* Site Navigation */}
                <div className="site-navigation">
                  <nav className="navigation">
                    {/* Main Menu */}
                    <div className="menu-wrapper">
                      <div className="menu-content">
                        <ul className="mainmenu">
                          {menuItems.map((item, index) => (
                            <li className="megamenu" key={index}>
                              <a className="nav-link" href={item.href}>
                                {item.label}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
                {/*  /.site-navigation */}

                <div className="header-navigation-right">
                  <div className="search-wrap">
                    <div className="search-btn" onClick={toggleSearch}>
                      {/* <FontAwesomeIcon icon={faSearch} /> */}
                    </div>
                    <div className={`search-form ${isSearchActive ? 'active' : ''}`}>
                      <form action="#">
                        <input type="search" placeholder="Search" />
                        <button type="submit">
                          {/* <FontAwesomeIcon icon={faSearch} /> */}
                        </button>
                      </form>
                    </div>
                  </div>
                  {/*~./ search-wrap ~*/}
                  <div
                    className={`hamburger-menus ${isMobileMenuActive ? 'click-menu' : ''}`}
                    onClick={toggleMobileMenu}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                {/*~./ header-navigation-right ~*/}
              </div>
              {/* /.navigation-area */}
            </div>
            {/* /.col-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
        <div className={`mobile-sidebar-menu sidebar-menu ${isMobileMenuActive ? 'sidemenu-active' : ''}`}>
          <div className="overlaybg" onClick={toggleMobileMenu}></div>
          <nav className="navigation">
            {/* Main Menu */}
            <div className="menu-wrapper">
              <div className="menu-content">
                <ul className="mainmenu">
                  {menuItems.map((item, index) => (
                    <li className="megamenu" key={index}>
                      <a className="nav-link" href={item.href}>
                        {item.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {/* End Site Header */}
    </>
  );
};

export default Navbar;
