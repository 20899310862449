import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import Navbar from '../../components/Navbar';
import Footer from '../../sections/Footer';
import Modal from '../../components/Modal';
import styles from './Gallery.module.css';

const Gallery = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const photosQuery = query(
          collection(db, 'uploads'),
          orderBy('createdAt', 'desc')
        );
        const querySnapshot = await getDocs(photosQuery);
        const photosData = querySnapshot.docs.map((doc) => doc.data());
        setPhotos(photosData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching photos: ', error);
        setLoading(false);
      }
    };
    fetchPhotos();
  }, []);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar page="gallery" />
      <div className={styles.gallery}>
        {photos.map((photo, index) => (
          <div
            key={index}
            className={styles.galleryItem}
            onClick={() => handlePhotoClick(photo)}
          >
            <img
              src={photo.imageUrl}
              alt={photo.title}
              className={styles.image}
            />
          </div>
        ))}
      </div>
      <Footer />
      <Modal
        show={selectedPhoto !== null}
        onClose={handleCloseModal}
        photo={selectedPhoto}
      />
    </div>
  );
};

export default Gallery;
