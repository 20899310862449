import React, { useState, useEffect } from "react";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "./style.css";

const EventsNews = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsQuery = query(
          collection(db, "newevents"),
          orderBy("date", "desc")
        );
        const querySnapshot = await getDocs(eventsQuery);
        const eventsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events: ", error);
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div id="event" className="events-news">
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-11 d-flex flex-column">
            <div className="events-list">
              <div className="section_our_solution">
                <div className="our_solution_category">
                  <div className="solution_cards_box">
                    {events.map((event, ind) => {
                      if (ind < 3) {
                        return (
                          <div key={event.id} className="solution_card">
                            <div className="hover_color_bubble"></div>
                            <div className="solu_title">
                            <h3 className="d-flex justify-content-between">
                              <b>{event?.title}</b>
                              <span>{new Date(event?.date.seconds * 1000).toLocaleDateString()}</span>
                            </h3>
                              
                            </div>
                            <div className="solu_description">
                              <p>{event?.description}</p>
                              <button type="button" className="read_more_btn">
                                Read More
                              </button>
                            </div>
                          </div>
                        )}
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-1">
            <div className="titleArea">
              <h1 className="commonTittle">EVENTS</h1>
              <p className="subTitle">In the news</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsNews;
